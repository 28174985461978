<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/ex_c.png" alt="" width="28">
        <h2>生产课题</h2>
        <span class="num">共计：{{ page.total }}条记录</span>
      </div>
      <el-button size="small">历史记录</el-button>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="$router.push({name: 'addTask'})">新增课题</el-button>
        <el-input clearable v-model="search" placeholder="请输入课题编号" suffix-icon="el-icon-search"
                  @change="skipPage(1)"></el-input>
      </div>
      <el-table
          border
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 320px)">
        <el-table-column
            prop="number"
            label="课题编号"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="name"
            label="课题名称"
            show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="dirNickname"
            label="负责人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
            prop="strain"
            label="动物品系" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="cageNum"
                         label="申请笼舍数量">
        </el-table-column>
        <el-table-column
            label="计划开始时间" min-width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.startTime">{{ scope.row.startTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="实验状态" width="120">
          <template slot-scope="scope">
            <el-button size="small" class="suspended" v-if="scope.row.status==0">草稿</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==2">实验审批中</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==4">已拒绝</el-button>
            <el-button size="small" class="approval" v-else-if="scope.row.status==14">兽医审核不通过</el-button>
            <el-button size="small" class="finished" v-else-if="scope.row.status==40">已完成</el-button>
            <el-button size="small" v-else class="experimentCo">
              <template v-if="scope.row.status==5">去请购</template>
              <template v-if="scope.row.status==6">动物请购中</template>
              <template v-if="scope.row.status==8">去接收</template>
              <template v-if="scope.row.status==9">动物接收中</template>
              <template v-if="scope.row.status==10">去检疫</template>
              <template v-if="scope.row.status==11">动物检疫中</template>
              <template v-if="scope.row.status==12">兽医复核中</template>
              <template v-if="scope.row.status==15">去申领</template>
              <template v-if="scope.row.status==16">申领中</template>
              <template v-if="scope.row.status==30">去合笼</template>
              <template v-if="scope.row.status==31 ||scope.row.status==33">动物合笼中</template>
              <template v-if="scope.row.status==35">新生动物</template>
              <template v-if="scope.row.status==38 ||scope.row.status==39 ">合笼终点</template>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row.id)">详情</el-button>
            <el-button type="text" v-if="scope.row.status==0" @click="toEdit(1,scope.row.id)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "taskList",
  data() {
    return {
      search: '',
      tableData: [],
      page: {
        current: 1,
        size: 10,
         total: 10,
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getData();
    },
    getData() {
      let that = this
      let params = {
        pageSize: that.page.size,
        pageNum: that.page.current,
        key: that.search,
        type: 1
      }
      that.$get("/subject/byPage", params).then((res) => {
        if (res.status == 200) {
          // if()
          this.tableData = res.data.records;
          this.page.current = res.data.current;
          this.page.total = res.data.total;
          this.page.size = res.data.size;
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    toDetail(id) {
      this.$router.push({name: 'taskDetail', query: {id: id}})
    },
    toEdit(state, id) {
      this.$router.push({
        name: 'addTask',
        query: {state: state, id: id}
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/experiment";
</style>
